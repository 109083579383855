@import '../../sass.config/vars.scss';

.deposit__back-button {
    position: absolute;
    left: 0;
    top: 0;
}

.wallet-address__confirm-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
}

.choose-wallet__item {
    position: relative;
    display: flex;
    justify-content: space-between;
    background-color: $item;
    padding: 10px 10px;
    border-radius: 20px;
    margin-top: 10px;
    cursor: pointer;
    &:hover {
        opacity: .8;
    }
    &::after {
        content: '';
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%) rotate(-45deg);
        border: solid $white;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transition: transform .3s;
    }
    &-title {
        display: flex;
        gap: 10px;
        font-size: 20px !important;
        text-transform: uppercase;
        align-items: center;
    }
}