@import "../../../sass.config/vars.scss";

.input-select {
    &-container {
        padding: 26px 29px;
        background-color: $item !important;
        border: none !important;
        border-radius: 20px !important;
        width: 100%;
        height: 71px;
    }
    &__control {
        background: none !important;
        border: none !important;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        overflow: hidden;

        div &--is-focused {
            outline: none !important;
            border: none !important;
            box-shadow: none;
        }
    }
    &__placeholder {
        opacity: 1 !important;
        color: $white !important;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1;
        margin-right: 20px !important;
    }
    &__single-value {
        position: relative;
        z-index: 1;
        opacity: 1 !important;
        color: $white !important;
        margin-right: 20px !important;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1;
        overflow: visible !important;
    }
    &__menu {
        top: 60% !important;
        padding: 0px 25px;
        left: 0px;
        background-color: $item !important;
        border: none !important;
        border-radius: 0px 0px 20px 20px !important;
        box-shadow: none !important;
    }
    &__option {
        &--is-selected {
            background: none !important;
        }
        &--is-focused {
            background: none !important;
            opacity: 0.8;
        }
    }
    &__indicator-separator {
        display: none;
    }
}
