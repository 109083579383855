.no-scroll {
    overflow: hidden;
}

.loader {
    &-wrapper {
        position: absolute;
        z-index: 999;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
    &-bg {
        position: fixed;
        z-index: 99;
        width: 100vw;
        height: 100vh;
        background-color: black;
        opacity: 0.8;
        top: 0;
    }
}
