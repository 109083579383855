@import './vars.scss';

/*=======Обнуляющие стили========*/
*,
*:before,
*:after {
padding: 0;
margin: 0;
border: 0;
box-sizing: border-box;
font-size: 16px;
font-weight: 400;
font-family: $Manrope;
}

*::selection {
background-color: $gold;
color: #f2f2f2;
}

html,
body {
height: 100%;
width: 100%;
color: #E3E8EE;
background-color: black;
scroll-behavior: smooth;
}

*:focus,
:active {
outline: none;
}
a,
a:visited,
a:focus {
    text-decoration: none;
    cursor: pointer;
}
button {
    border: none;
    cursor: pointer;
}
a:focus-visible,
button:focus-visible{
   border: 2px solid rgba(250, 216, 0, .7);
}

li {
list-style: none;
}

input:focus::placeholder {
color: transparent;
transition: all 0.2s ease;
}
.wrapper {
min-height: 100%;
display: flex;
flex-direction: column;
}
main {
flex: 1 1 auto;
width: 100%;
}
body.fixed-page {
overflow: clip;
}
section {
overflow-x: clip;
width: 100%;
}
header,footer{
overflow-x: clip;
width: 100%;
}

::-webkit-scrollbar {
    width: 0;
  }

  