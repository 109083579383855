@import "../../../sass.config/vars.scss";

.link-btn {
    display: inline-block;
    color: $white;
    font-size: 16.36px;
    font-weight: 500;
    &__gold {
        color: $gold;
    }
    text-decoration: none;
    opacity: 0.6;
    cursor: pointer;
    &:hover {
        opacity: 1;
    }
}

@media screen and (max-width: 768px) {
    .link-btn {
        font-size: 14px;
    }
}
