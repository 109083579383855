.promotion-card {
    margin-top: 10px;
    padding: 37px 47px 85px 47px !important;
    &-main__item {
        &-text {
            display: flex;
            flex-direction: column;
            row-gap: 8px;
            h3 {
                font-size: 30px;
                font-weight: 600;
            }
            p {
                font-family: "Gilroy";
                font-size: 17px;
                font-weight: 300;
                line-height: 170%;
                opacity: 0.9;
            }
        }
    }
}
