@import "../../../sass.config/vars.scss";

.button-group {
    &__wrapper {
        display: flex;
        column-gap: 4px;
    }
    &__divider {
        &:last-child {
            display: none;
        }
        height: 20px;
        width: 1px;
        background-color: $white;
    }
}
