@import "../../sass.config/vars.scss";

.main__nav-item {
    position: relative;
    grid-column: span 1;
    grid-row: span 1;
    &-flex {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
    }
    padding: 27px 37px;
    overflow: hidden;
    background: $gold;
    border-radius: 25px;
    &-icon-gold {
        & svg {
            color: $gold
        }
    }
    &-icon-dark {
        & svg {
            color: $item
        }
    }
    &-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: 5px;
    }
    &.deposit {
        img {
            top: -40%;
            left: 100%;
        }
    }
    &.selected {
        background: $btn;
        color: $gold;
        & .main__nav-item-title > h6 {
            color: $gold !important;
        }
    }
    h6 {
        position: relative;
        z-index: 2;
        font-size: 20px;
        font-weight: 800;
        color: #070709;
    }
    img {
        position: absolute;
        bottom: 0;
        left: 75%;
        transform: translateX(-75%);
        object-fit: cover;
    }
}

@media screen and (max-width: 1268px) {
    .main__nav-item {
        &-title {
            color: $gold !important;
        }
        &-mobile {
            overflow: visible;
            padding: 20px 30px;
            background: $btn;

            h6 {
                color: $gold;
                font-size: 18px;
            }
            img {
                display: none;
            }
        }
    }
}
