@import "../../../sass.config/vars.scss";

.settings {
    &__wrapper {
        display: flex;
        column-gap: 30px;
        @media (max-width: 768px) {
            flex-direction: column;
        }
    }

    &-paper {
        margin-bottom: 10px;
        &__wrapper {
            width: 70%;
            @media (max-width: 768px) {
                width: 100%;
            }
        }
    }

    // &-contact {
    //     &__wrapper {
    //         margin-top: 20px;
    //         display: flex;
    //         column-gap: 10px;
    //         @media (max-width: 400px) {
    //             flex-direction: column;
    //         }
    //     }
    //     &__select {
    //         width: 30%;
    //         height: 74.5px;
    //         @media (max-width: 768px) {
    //             width: 50%;
    //         }
    //         @media (max-width: 400px) {
    //             width: 100%;
    //         }
    //     }
    //     &__input {
    //         @media (max-width: 400px) {
    //             margin-top: 10px;
    //         }
    //     }
    // }

    &-actions {
        &__wrapper {
            width: 30%;
            @media (max-width: 768px) {
                width: 100%;
            }
        }

        &__logout {
            margin: 0 auto;

            &-icon {
                color: $logout;
            }
        }

        &__button {
            position: relative;
            margin-top: 30px;
            z-index: 1;
        }
    }
}
