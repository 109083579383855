@import '../../../sass.config/vars.scss';

.main-input {
    background: $item;
    border-radius: 44px;
    color: $white;
    padding: 26px 29px;
    font-size: 16px;
    letter-spacing: 1;
    width: 100%;
    height: 48px;
    &::placeholder {
        opacity: .25;
    }
}