@import "../../sass.config/vars.scss";

.auth-paper {
    position: relative;
    padding-top: 66px;
    display: block;
    margin: 0 auto;
    width: 586px;
    height: auto;
    background-color: $btn;
    &-title {
        text-align: center;
        margin-bottom: 21px;
    }
}

@media screen and (max-width: 768px) {
    .auth-paper {
        width: 95%;
        &-title {
            font-size: 20px;
        }
    }
}
