@import '../../../sass.config/vars.scss';

.main__aside-item {
    display: flex;
    column-gap: 18px;
    padding: 18px 35px;
    background: $btn;
    
    border-radius: 20px;
    transition: all .15s ease-in-out;
    &.selected {
        background: $gold;
        box-shadow: 0 4px 32px 0 rgba(251, 204, 12, 0.3);
        svg {
            display: none;
        }
        h4{
            color: #000;
        }
    }
    h4{
        color: $btn-text;
        font-size: 20px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 10px;
    }
    &:hover{
        box-shadow: 0 4px 32px 0 rgba(83, 83, 83, 0.3);
    }
}


@media (max-width: 894px) {
    .main__aside-item {
        padding: 10px 25px;
        border-radius: 15px;
        h4{
            font-size: 18px;
        }
    }
}