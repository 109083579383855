@import '../../sass.config/vars.scss';

.time-item{
    display: flex;
    align-items: center;
    column-gap: 8px;
    color: $gold;
}

@media (max-width: 768px) {
    .time-item{
        color: $orange;
    }
    
}