@import '../authPage/authPage.scss';

.contact-wrapper {
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
}

.auth-buttons-wrapper.register {
    justify-content: space-between;
}