@import "../../../sass.config/vars.scss";

.icon-btn {
    &-selected {
        background: $btn;
        color: #bfbfbf;
    }
    &-dark {
        background: $item;
        color: $white;
    }
    &-gold {
        color: $gold;
    }
    &-noBg {
        background: inherit;
        color: $white;
        padding: 0;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
    padding: 12px 26px;
    text-align: center;
    background: $gold;
    color: #000;
    border-radius: 24px;
    transition: all 0.2s ease;
    cursor: pointer;
    &:hover {
        opacity: 0.9;
    }
}

@media screen and (max-width: 768px) {
    .btn {
        padding: 10px 20px;
        font-size: 14px;
    }
}
