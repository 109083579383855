@import "../../sass.config/vars.scss";

.header {
    &__menu {
        display: flex;
        flex-direction: column;
        row-gap: 35px;
        padding: 45px 0;
    }

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__account-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 48px;
        & > li svg {
            font-size: 30px;
        }
    }
}

@media screen and (max-width: 894px) {
    .header {
        // &__menu{
        //     row-gap: 20px;
        //     padding: 20px 0;
        // }
        &__account-info {
            column-gap: 20px;
            & > li svg {
                font-size: 27px;
            }
        }
        .logo img {
            width: 125px;
        }
    }
    .mobile_menu {
        position: relative;
    }
    .menu-button {
        position: relative;
        z-index: 9;
        color: $gold;
        cursor: pointer;
    }
}
