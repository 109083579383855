@import '../../../sass.config/vars.scss';

.checkbox {
    &-wrapper {
        position: relative;
        display: flex;
        align-items: center;
    }
    &-input {
        position: relative;
        appearance: none;
        width: 24px;
        height: 24px;
        border: 0.1px solid $darkGold;
        background-color: $dark;
        border-radius: 7.6px;
        border-bottom: none;
        border-top: none;
        &:not(:checked) ~ .checkbox-checkmark {
            opacity: 0;
        }
        &:checked ~ .checkbox-checkmark {
            opacity: 1;
        }
    }
    label {
        max-width: 191px;
    }
    &-checkmark {
        position: absolute;
        left: -1px;
        top: -4px;
        pointer-events: none;
        transition: all 0.1s;
    }
    position: relative;
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin-top: 10px;
}
// .checkbox {
//     visibility: hidden;
//     &-wrapper {
//         position: relative;
//         display: flex;
//         align-items: center;
//         column-gap: 15px;
//         margin-top: 10px;
//     }
//     & + label {
//         max-width: 171px;
//     }
//     &:not(checked) + label::before, &:checked + label::before {
//         content: "";
//         position: absolute;
//         left: 0px;
//         top: 0px;
//         width: 24px;
//         height: 24px;
//         border: 0.1px solid $darkGold;
//         background-color: $dark;
//         border-radius: 7.6px;
//         border-bottom: none;
//         border-top: none;
//     }
//     &-checkmark {
//         position: absolute;
//         left: -1px;
//         top: -4px;
//     }
// }