@import "../../sass.config/vars.scss";

.main__item {
    position: relative;
    grid-column: span 3;
    grid-row: span 1;
    &-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    column-gap: 100px;
    padding: 37px 47px;
    background: $item;
    &-dark {
        background-color: $btn;
    }
    color: #fff;
    border-radius: 25px;
    &.outlined {
        border: solid 1px $darkGold;
    }
}

@media (max-width: 768px) {
    .main__item {
        &.outlined {
            border: solid 0.1px $darkGold;
        }
    }
}
