@import '../../sass.config/vars.scss';

.main__aside{
    .main__aside-block{
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        max-width: 280px;
        width: 220px;
    }
}

@media (max-width: 894px) {
    .main__aside{
        display: none;
    }
}