.order {
    &-input__wrapper {
        display: block;
        padding-top: 5px;
        margin-top: 10px;
    }
    &-input {
        height: 40px;
        width: 100%;
        &__installs {
            margin-top: 10px;
        }
    }
    &-btns__wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }
}
