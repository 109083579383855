@import "../../sass.config/vars.scss";

.news_card {
    margin-top: 10px;
    
    &-main__item {
        width: 100%;
        position: relative;
        &-ring-wrapper {
            width: 100%;
            position: relative;
        }
        &-text {
            display: flex;
            flex-direction: column;
            row-gap: 8px;
            width: 800px;
            h3 {
                font-size: 30px;
                font-weight: 600;
            }
            p {
                font-family: "Gilroy";
                font-size: 17px;
                font-weight: 300;
                line-height: 170%;
                opacity: 0.9;
                overflow-wrap: break-word;
            }
        }
        &-info {
            position: absolute;
            right: 0;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            & > button {
                margin-right: 10px;
            }
        }
        &-icon {
            display: none;
        }
    }
}

@media screen and (max-width: 1268px) {
    .news_card {
        background-color: inherit;
        border-bottom: $darkGold 0.1px solid;
        padding: 0;
        padding-bottom: 10px;
        border-radius: 0;
        align-items: flex-start;
        column-gap: 5px;
        &-main__item {
            &-text {
                row-gap: 10px;
                max-width: 500px;
                width: 100%;
                h3 {
                    font-size: 25px;
                    line-height: 1;
                }
                p {
                    font-size: 16px;
                    line-height: 1;
                    width: 76%;
                }
            }
            &-ring-wrapper {
                display: flex;
                align-items: center;
                column-gap: 20px;
            }
            &-icon {
                position: relative;
                background-color: $item;
                border-radius: 50px;
                padding: 0;
                height: 80px;
                min-width: 80px;
                display: flex;
                justify-content: center;
                & > svg {
                    color: $gold;
                    transform: rotate(-8deg);
                    width: 35px;
                    height: 35px;
                }
                align-items: center;
                &.active {
                    &::after {
                        display: block;
                        content: "";
                        position: absolute;
                        bottom: 3px;
                        right: 7px;
                        background-color: $gold;
                        width: 12px;
                        height: 12px;
                        border-radius: 100%;
                        box-shadow: 0 0 0 0 $darkGold;
                        transform: scale(1);
                        animation: pulse 2s infinite;
                    }
                }
            }
        }
    }
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 $darkGold;
    }

    60% {
        transform: scale(1);
        box-shadow: 0 0 0 7px $darkGold;
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 $darkGold;
    }
}
