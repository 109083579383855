// Шрифты:
$Manrope: "Manrope";
$Gilroy: "Gilroy";

// Цвета:
$gold: #f6d758;
$orange: #ebbd41;
$btn: #0d0d0f;
$white: #fff;
$light: rgba(255, 255, 255, 0.1);
$item: #141415;
$dark: #333;
$darkGold: rgba(252, 213, 53, 0.4);
$btn-text: #bfbfbf;
$error: #f6d758;
$logout: #fc3535;
$valid: rgb(0, 255, 0);
