

.nav_items {
    display: flex;
    column-gap: 30px;
    & > .main__nav-item {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .nav_items {
        column-gap: 10px;
    }
}