@import "../../sass.config/vars.scss";

.modal-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
}

.modal {
    position: fixed;
    padding-top: 10px;
    padding-bottom: 100px;
    z-index: 101;
    width: 586px;
    height: auto;
    background-color: $item;
    border-radius: 20px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    &-dark {
        background-color: $btn;
    }
    &-small {
        width: 212px;
    }
    &-without-padding {
        padding-bottom: 0;
    }
    &-center {
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
    &-big {
        width: 1180px;
    }
    &-top {
        top: 0;
    }
    &-left {
        left: 0;
    }
    &-right {
        right: 0;
    }
    &-bottom {
        bottom: 0;
    }
    &-content {
        max-width: 80%;
        margin: 0 auto;
    }
    &-title {
        text-align: center;
        margin-bottom: 21px;
    }
    &-btns {
        position: absolute;
        display: flex;
        column-gap: 5px;
        bottom: 10px;
        right: 10px;
    }
}

@media (max-width: 768px) {
    .modal {
        width: 97%;
        &-small {
            width: 212px;
        }
        &-content {
            max-width: 80%;
        }
        &-big {
            width: 90%;
            margin: 0 auto;
        }
    }
}
