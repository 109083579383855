@font-face {
	font-family: 'Manrope';
	font-weight: 600;
	font-style: normal;
	font-display: swap;
	src:
		local('Arimo-Regular'),
		url('../fonts/Manrope-SemiBold.woff2') format('woff2');
}

@font-face {
	font-family: 'Manrope';
	font-weight: 700;
	font-style: normal;
	font-display: swap;
	src:
		local('Arimo-SemiBold'),
		url('../fonts/Manrope-Bold.woff2') format('woff2');
}
@font-face {
	font-family: 'Manrope';
	font-weight: 800;
	font-style: normal;
	font-display: swap;
	src:
		local('Arimo-SemiBold'),
		url('../fonts/Manrope-ExtraBold.woff2') format('woff2');
}

@font-face {
	font-family: 'Gilroy';
	font-weight: 300;
	font-style: normal;
	font-display: swap;
	src:
		local('Arimo-SemiBold'),
		url('../fonts/Gilroy-Light.woff2') format('woff2');
}
@font-face {
	font-family: 'Gilroy';
	font-weight: 800;
	font-style: normal;
	font-display: swap;
	src:
		local('Arimo-SemiBold'),
		url('../fonts/Gilroy-ExtraBold.woff2') format('woff2');
}

