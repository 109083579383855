.orders-more__btn {
    display: block;
    margin: 0 auto;
}

.order-edit {
    &__text-error {
        margin-left: 10px;
        margin-top: 5px;
    }
}
