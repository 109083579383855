@import '../../../sass.config/vars.scss';



.subtitle {
    color: $white;
    opacity: .8;
    font-size: 16px;
    font-weight: 500;
    &-error {
        color: $error;
    }
}

@media screen and (max-width: 768px) {
    .subtitle {
        font-size: 14px;
    }
}