// @import '../../../sass.config/vars.scss';

.main{
    padding: 0 0 70px 0;
    &__block {
        width: 100%;
    } 
    &__body {
        display: flex;
        justify-content: space-between;
        column-gap: 30px;
    }
    // &__content{
    //     display: grid;
    //     grid-template: repeat(7, 1fr) / repeat(3, 1fr);
    //     gap: 25px;
    // }
    // &__nav {
    //     display: flex;
    //     flex-direction: column;
    //     row-gap: 35px;
    //     padding: 45px 0;
    // }
}





