@import "../../../sass.config/vars.scss";

.drop {
    &_zone {
        border: 2px dashed $dark;
        padding: 15px 16px;
        border-radius: 5px;
        cursor: pointer;
        &-text {
            display: flex;
            align-items: center;
            column-gap: 10px;
            &.active {
                opacity: 0.2;
            }
            &.valid {
                color: $valid;
                opacity: 0.8 !important;
            }
        }
    }
}
