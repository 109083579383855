@import '../../../sass.config/vars.scss';

.auth-link {
    color: $white;
    font-size: 16.36px;
    font-weight: 500;
    &-gold {
        color: $gold;
        &:visited {
            color: $gold !important;
        }
    }
    text-decoration: none;
    opacity: .6;
    &:visited {
        color: $white;
        text-decoration: none;
        cursor: pointer;
    }
    &:hover {
        opacity: 1;
    }
}

@media screen and (max-width: 768px) {
    .auth-link {
        font-size: 14px;
    }
}