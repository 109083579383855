.settings {
    &-company {
        &__contacts {
            &-wrapper {
                margin-top: 41px;
                display: flex;
                gap: 1%;
                flex-wrap: wrap;
                &-item {
                    margin-top: 5px;
                    & > svg {
                        font-size: inherit;
                    }
                    @media (max-width: 400px) {
                        font-size: 15px;
                    }
                }
            }
        }
    }
}
