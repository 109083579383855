@import "../../../sass.config/vars.scss";

.title {
    color: $white;
    opacity: 0.9;
    font-size: 26px;
    font-weight: 400;
    &-error {
        color: $error;
    }
    &-gold {
        color: $gold;
    }
}

@media screen and (max-width: 768px) {
    .title {
        font-size: 20px;
    }
}
