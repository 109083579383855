@import '../../../sass.config/vars.scss';

.notifications {
    position: relative;
    display: block;
    border: none;
    background: none;
    width: 30.75px;
    height: 30.75px;
    rotate: -8deg;
    & > svg {
        color: $dark;
    }
    &_active {
        & > svg {
            animation: bellshake 2s infinite;
        }
        &::after {
            display: block;
            content: '';
            position: absolute;
            bottom: 0px;
            right: 0px;
            background-color: rgb(252, 53, 53);
            width: 8.7px;
            height: 8.7px;
            border-radius: 100%;
            
            box-shadow: 0 0 0 0 rgba(255, 0, 0, 1);
            transform: scale(1);
            animation: pulse 2s infinite;
        }
    }
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(252, 53, 53, 0.336);
	}

	50% {
		transform: scale(1);
		box-shadow: 0 0 0 2px rgba(252, 53, 53, 0.336);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(252, 53, 53, 0.336);
	}
}

@keyframes bellshake {
    0% { transform: rotate(0); }
    15% { transform: rotate(5deg); }
    30% { transform: rotate(-5deg); }
    45% { transform: rotate(4deg); }
    60% { transform: rotate(-4deg); }
    75% { transform: rotate(2deg); }
    85% { transform: rotate(-2deg); }
    92% { transform: rotate(1deg); }
    100% { transform: rotate(0); }
  }