.settings {
    &-contact {
        &__submit {
            display: flex;
            justify-content: flex-end;
            margin-top: 10px;
        }

        &__wrapper {
            margin-top: 20px;
            display: flex;
            column-gap: 10px;
            @media (max-width: 400px) {
                flex-direction: column;
            }
        }
        &__select {
            width: 30%;
            height: 74.5px;
            @media (max-width: 768px) {
                width: 50%;
            }
            @media (max-width: 400px) {
                width: 100%;
            }
        }
        &__input {
            @media (max-width: 400px) {
                margin-top: 10px;
            }
        }
    }
}