

.logo {
    display: flex;
    align-items: center;
    gap: 16px;
    &__icon {
        & > g > path {
            transition: transform .3s;
        }
    }
}

@keyframes logoHeadTransform {
    0% { transform: matrix(1,0,0,1,0,-0.5); }
    15% { transform: matrix(1,0,0,1,0,-1); }
    30% { transform: matrix(1,0,0,1,0,-1.5); }
    45% { transform: matrix(1,0,0,1,0,-2); }
    60% { transform: matrix(1,0,0,1,0,-2.5); }
    75% { transform: matrix(1,0,0,1,0,-3); }
    85% { transform: matrix(1,0,0,1,0,-3.5); }
    100% { transform: matrix(1,0,0,1,0,-4); }
}