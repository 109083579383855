@import "../../../sass.config/vars.scss";

.auth-input {
    position: relative;
    background: $item;
    border-radius: 20px;
    color: $white;
    padding: 26px 29px;
    font-size: 16px;
    letter-spacing: 1;
    width: 100%;
    height: 100%;
    &-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        height: 71px;
        width: 100%;
    }
    &__dark {
        background-color: $dark;
    }
    &::placeholder {
        opacity: 0.25;
    }
}
