@import '../../sass.config/vars.scss';

.balance {
    display: flex;
    column-gap: 16px;
    padding: 10px 30px;
    background: $btn;
    align-items: center;
    color: $gold;
    border-radius: 25px;
    &-dark {
        background-color: $item;
    }
    &-fullwidth {
        width: 100%;
        justify-content: center;
    }
    &-size {
        &__big {
            & span {
                font-size: 30px;
            }
        }
        &__medium {
            & span {
                font-size: 20px;
            }
        }
        &__small {
            & span {
                font-size: 14px;
            }
        }

    }
}

@media screen and (max-width: 768px) {
    .balance {
        & > svg {
            display: none;
        }
        padding: 8px 20px;
    }
}