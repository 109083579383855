@import '../../sass.config/vars.scss';

.main__nav-item-btns{
    position: relative;
    margin-top: 50px;
    z-index: 2;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    .main__item-btn-first{
        color: $gold;
        background-color: $item;
        cursor: default;
        &:hover {
            opacity: 1;
        }
    }
    .main__item-btn-second{
        background: $gold;
        color: $btn;
    }
    &-center {
        justify-content: center;    
    }
    &-end {
        justify-content: flex-end;
    }
    &-start {
        justify-content: flex-start;
    }
    &-between {
        justify-content: space-between;
    }
    &-around {
        justify-content: space-around;
    }
    &-evenly {
        justify-content: space-evenly;
    }
}

.deposit {
    &-button__wrapper {
        display: flex;
        column-gap: 10px;
    }
    & > svg {
        color: $gold
    }
}

@media(max-width: 768px){
    .main__nav-item-btns{
        display: none;
    }
}