@import "./sass.config/base.scss";

.container {
    max-width: 1850px;
    padding: 0 15px;
    margin: 0 auto;
}

section {
    position: relative;
    padding: 70px 0;
    @media (max-width: 768px) {
        & {
            padding: 50px 0;
        }
    }
}

.toast {
    background-color: $dark !important;
    color: $white !important;
}
