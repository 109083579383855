@import "../../../sass.config/vars.scss";

.auth-label {
    height: 27px;
    color: $white;
    font-size: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5px;
    margin-bottom: 10px;
    margin-top: 40px;
    &-icon {
        & > svg {
            color: $gold;
        }
        &__white > svg {
            color: $white;
        }
    }
}

@media screen and (max-width: 768px) {
    .auth-label {
        font-size: 16px;
    }
}
