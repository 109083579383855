@import "../../sass.config/vars.scss";

.main-table {
    margin-top: 10px;
    border-spacing: 0px 10px;
    position: relative;
    border-collapse: separate;
    width: 100%;
    &__container {
        overflow-x: auto; /* Добавляем горизонтальную прокрутку, если содержимое шире контейнера */
        margin: 0 auto;
        max-width: 100%;
    }
    &__columns {
        color: $white;
        & th {
            border-bottom: $light 1px solid;
            // padding-right: 20px;
            padding-bottom: 10px;
            & > div {
                display: flex;
                width: 100%;
                justify-content: center;
                align-items: center;
                column-gap: 5px;
            }
        }
    }
    &__row {
        &-default {
            height: 119px;
        }
        &-data-center {
            & > div {
                text-align: center;
            }
        }
        &-data-main {
            background-color: $item;

            @media (max-width: 765px) {
                border-right: 10px solid transparent;
            }
            &:first-child {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }
            &:last-child {
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
            }
            & > div {
                display: flex;
                justify-content: center;
                & span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $dark;
                    padding: 10px 28px;
                    text-align: center;
                    border-radius: 23px;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .main-table {
        & span {
            font-size: 12px;
        }
    }
}
